import React from "react"
import Layout from "../components/Layout/Layout"
import Header from "../components/Layout/Header"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import Seo from "../components/Layout/Seo"
import ImageFromNewt from "../components/Atomic/ImageFromNewt"
import BlackButton from "../components/Atomic/button/BlackButton"
import { Link } from "gatsby"


export default function lifestyleArchive({ pageContext }) {
  const {
    breadcrumb: { crumbs },
  } = pageContext
  const { title, eyecatch, type, autor, contents_md } = pageContext.post
  const { previous, next } = pageContext
  console.log(next)
  console.log(previous);
  return (
    <div>
      <Layout>
        <Header />
        <div className="max-w-screen-md px-6 m-auto pt-12">
          <Breadcrumb
            crumbs={crumbs}
            crumbSeparator=" / "
            crumbLabel={title}
          />
          <article className="py-20">
            <div className="grid gap-8 place-items-center mb-8">
              <span className="inline-block border-2 border-omcBlueGray text-omcBlueGray font-alphabet rounded-full px-4">{type}</span>
              <h1 className="md:text-3xl text-2xl font-gothic text-omcBlueGray text-center">{title}</h1>
              <p className="font-gothic">written by : {autor}</p>
            </div>
            <ImageFromNewt src={eyecatch.src} alt={title} />



            <div dangerouslySetInnerHTML={{ __html: contents_md }} className="pt-12 entry entry-content"></div>
          </article>

          <section className="grid md:grid-cols-2 gap-4">
            {previous &&
            <div className="border p-3">
              <Link to={`/life-style/${previous.slug}`} className="grid grid-cols-3 gap-4 place-items-center">
                <ImageFromNewt src={previous.eyecatch.src} alt={previous.title} className={"object-cover h-auto"} />
                <h3 className="col-span-2 text-sm">{previous.title}</h3>
              </Link>
            </div>
            }
            {next &&
            <div className="border p-3 md:col-start-2">
              <Link to={`/life-style/${next.slug}`} className="grid grid-cols-3 gap-4 place-items-center">
                <ImageFromNewt src={next.eyecatch.src} alt={ next.title} className={"object-cover h-auto"} />
                {<h3 className="col-span-2 text-sm">{next.title}</h3>}
              </Link>
            </div>
            }
          </section>

          <div className="flex py-12 justify-center">
            <BlackButton text="一覧へ戻る" link="/life-style/page/1" className="m-auto" />
          </div>
        </div>
      </Layout>
    </div>
  )
}

export const Head = ({ pageContext }) => (
  <>
    <Seo title={pageContext.post.title}
      description={pageContext.post.contents_md.substr(0, 120)}
      ogpImgPath={pageContext.post.eyecatch.src}
    />
    <script async="" type="text/javascript" src="https://assets.pinterest.com/js/pinit.js"></script>
    <script async src="https://platform.twitter.com/widgets.js"></script>
    <script async src="https://www.instagram.com/embed.js"></script>
    <script async src="//iframely.net/embed.js"></script>
  </>
)
